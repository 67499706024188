@import "~@/styles/variables/variables.scss";




















































.search-box {
	position: absolute;
	top: 20px;
	left: 20px;
	display: flex;
	z-index: 100;
	.run-board-search {
		background: #fff;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-color: #fff;
	}
	.search-icon {
		padding: 11px 11px;
		background: #4d6bff;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		cursor: pointer;
		i {
			color: #fff;
			font-size: 18px;
		}
	}
}
