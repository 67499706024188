@import "~@/styles/variables/variables.scss";


















































































































































































































































































.list-box {
	position: absolute;
	top: 20px;
	right: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	.list-ops {
		width: 100px;
		height: 48px;
		line-height: 48px;
		background: #4d6bff;
		border-radius: 4px;
		color: #fff;
		text-align: center;
		font-size: 14px;
		cursor: pointer;
		margin-bottom: 20px;
		i {
			font-size: 14px;
		}
		span {
			padding-left: 5px;
		}
	}
	.close-list {
		i {
			display: inline-block;
			transform: rotate(180deg);
		}
	}
	.list-show {
		width: 416px;
		height: calc(100vh - 200px);
		background: #e7e8f2;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		.title {
			height: 36px;
			background: rgba(110, 133, 255, 0.95);
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 0 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			color: #fff;
			.close-icon {
				cursor: pointer;
			}
		}
		.alarm-search {
			padding: 12px;
			display: flex;
			.gc-custom-search {
				background: #fff;
			}
			.time-search {
				padding-left: 10px;
				display: flex;
				&-item {
					width: 57px;
					height: 100%;
					background: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
				}
				&-item.active {
					background: #7b90fe;
					color: #fff;
				}
				&-item:nth-child(1) {
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				&-item:last-child {
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}
		}
		.content {
			flex: 1;
			height: 0;
			overflow: auto;
			padding: 0 12px 12px 12px;

			.list-item {
				background: #fff;
				border-radius: 3px;
				padding: 10px 12px 0 12px;
				cursor: pointer;
				&-top {
					padding-bottom: 16px;
					.device-name {
						display: flex;
						align-items: center;
						justify-content: space-between;
						&-left {
							display: flex;
							align-items: center;
						}
						.icon-box {
							width: 25px;
							height: 25px;
							line-height: 25px;
							background: #eaedff;
							border-radius: 6px;
							text-align: center;
							i {
								font-size: 18px;
								color: #4d6bff;
							}
						}
						.name {
							padding-left: 7px;
							font-size: 16px;
							font-weight: 600;
							color: #333333;
						}
						.device-name-right {
							flex-shrink: 0;
							.time {
								font-size: 12px;
								color: #969cbb;
							}
							i {
								cursor: pointer;
								padding: 5px 5px 5px 10px;
								color: #6b76ac;
							}
						}
					}
					.spec-info {
						padding-left: 32px;
						.info-item {
							display: flex;
							align-items: flex-start;
							padding-top: 10px;
							vertical-align: middle;
							i {
								font-size: 14px;
								color: #6b7399;
							}
							.value {
								display: inline-block;
								padding-left: 5px;
								color: #5c6073;
							}
						}
					}
				}
				&-bottom {
					padding: 0 12px 10px 32px;
					border-top: 1px solid #cccccc;
					.alarm-tag {
						display: inline-block;
						color: #fff;
						border-radius: 15px;
						padding: 5px;
						margin: 10px 10px 0 0;
					}
				}
			}
			.list-item + .list-item {
				margin-top: 12px;
			}
		}
	}
}
::-webkit-scrollbar-track-piece {
	background: #e7e8f2 !important;
}
