@import "~@/styles/variables/variables.scss";










































































































































































































































.map-box {
	height: 100%;
	#boardContainer {
		height: 100%;
	}
}
