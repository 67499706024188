@import "~@/styles/variables/variables.scss";























































































































































































































































































.info-box {
	position: absolute;
	.icon-box {
		width: 24px;
		height: 24px;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 4px;
		text-align: center;
		line-height: 24px;
		color: #fff;
		font-size: 16px;
	}
	.spec-info {
		padding-left: 30px;
		.info-item {
			padding-top: 9px;
			display: flex;
			color: rgba(255, 255, 255, 0.8);
			font-size: 14px;
			i {
				padding-right: 5px;
				font-size: 14px;
			}
		}
	}

	.device-list {
		width: 295px;
		background: #fff;
		border-radius: 8px;
		&-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #7990ff;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 12px 12px 16px 12px;
			color: #fff;
			i {
				padding: 3px 0 3px 5px;
				cursor: pointer;
			}
		}
		.list-box {
			overflow: auto;
			max-height: 400px;
			position: relative;
			.list-item {
				padding: 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #333333;
				&-right {
					flex-shrink: 0;
					.device-tag {
						padding: 3px 5px;
						border-radius: 15px;
						font-size: 12px;
						color: #fff;
					}
					i {
						padding: 5px 0 5px 10px;
						cursor: pointer;
						color: #6b76ac;
					}
				}
				&.active {
					background: #ebeeff;
					.list-item-left {
						color: #7990ff;
					}
				}
				.list-item-left {
					padding-right: 8px;
					&-info {
						i {
							color: #6b7399;
							padding-right: 3px;
						}
						span {
							display: inline-block;
							word-break: break-all;
						}
						display: flex;
						line-height: 21px;
					}
				}
			}
			.list-item + .list-item {
				border-top: 1px dashed #cccccc;
			}
		}
		&::after {
			content: '';
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-right: 7px solid #7990ff;
			position: absolute;
			top: -10px;
			left: 55%;
			transform: rotate(90deg);
		}
	}
}
.device-card.have-parent-list {
	position: absolute;
	left: 310px;
	top: 0;
	&::after {
		content: '';
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-right: 7px solid #fff;
		position: absolute;
		bottom: 80%;
		left: -7px;
		transform: rotate(0deg);
	}
	&.normal::after {
		border-right: 7px solid #29c287;
	}
	&.offline::after {
		border-right: 7px solid #afb4cc;
	}
	&.alarm::after {
		border-right: 7px solid #ff9419;
	}
	&.fault::after {
		border-right: 7px solid #e5662e;
	}
}

.device-card {
	width: 456px;
	border-radius: 8px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	background: #ffffff;
	&-top {
		padding: 10px 16px 16px 16px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		background: #fff;
		.device-name {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&-left {
				display: flex;
				align-items: center;
				.icon-box {
					i {
						font-size: 18px;
					}
				}
				.name {
					padding-left: 6px;
					padding-right: 8px;
					font-weight: 600;
					font-size: 16px;
					color: #ffffff;
				}
				.device-tag {
					padding: 3px 5px;
					border-radius: 15px;
					background: #fff;
					font-size: 12px;
					color: #29c287;
				}
			}
			&-right {
				color: #fff;
				i {
					padding: 5px 0 5px 5px;
					cursor: pointer;
				}
			}
		}
		.spec-info-box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.device-name-right {
				padding-right: 20px;
				.el-button {
					padding: 0 10px;
					height: 30px;
					line-height: 30px;
					border-radius: 16px;
					background: #fff;
				}
			}
		}
	}
	&-bottom {
		padding: 20px 16px 16px 46px;
		.real-data {
			color: #858894;
			font-size: 14px;
			i {
				padding-right: 5px;
			}
		}
		.field-info {
			color: #666666;
			padding-top: 8px;
			line-height: 21px;
			display: flex;
			flex-wrap: wrap;
			.field-value {
				width: 50%;
				&.data-time {
					width: 100%;
				}
			}
		}
		.field-info-empty {
			text-align: center;
			padding-top: 20px;
			color: #666666;
		}
	}
	&::after {
		content: '';
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-right: 7px solid #fff;
		position: absolute;
		bottom: -10px;
		left: 67%;
		transform: rotate(-100deg);
	}
	&-empty {
		text-align: center;
		padding: 30px 0;
		img {
			width: 100px;
		}
		span {
			display: block;
			color: #5c6073;
			padding-top: 20px;
		}
		i {
			padding: 0 0 10px 20px;
			position: absolute;
			right: 12px;
			top: 12px;
			cursor: pointer;
		}
	}
}
