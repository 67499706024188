@import "~@/styles/variables/variables.scss";























































































































































.category-tab {
	min-width: 277px;
	max-height: 450px;
	background: #fff;
	position: absolute;
	top: 88px;
	left: 20px;
	border-radius: 8px;
	padding: 30px 20px;
	display: flex;
	.first-category,
	.device-status {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: auto;
	}
	.first-category {
		padding: 0 27px 0 10px;
		border-right: 1px dashed #737580;
		&-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			.icon-box {
				width: 46px;
				height: 46px;
				background: #e6e7f0;
				border-radius: 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				i {
					font-size: 30px;
					color: #98a1cb;
				}
			}
			.category-text {
				display: inline-block;
				font-size: 14px;
				font-weight: 600;
				color: #333333;
				line-height: 21px;
				margin-top: 8px;
			}
		}
		&-item.active {
			.icon-box {
				background: rgba(77, 107, 255, 0.12);
				i {
					color: #4d6bff;
				}
			}
			.category-text {
				color: #4d6bff;
			}
		}
		.first-category-item + .first-category-item {
			padding-top: 30px;
		}
	}
	.device-status {
		padding-left: 20px;
		flex: 1;
		&-item {
			width: 100%;
			height: 54px;
			line-height: 54px;
			border-radius: 4px;
			padding: 0 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			.status-text {
				font-size: 14px;
				line-height: 21px;
			}
			.status-num {
				font-weight: 700;
				font-size: 22px;
				line-height: 26px;

				padding-left: 20px;
			}
		}
	}
}
