@import "~@/styles/variables/variables.scss";

































.run-board {
	height: 100%;
	width: 100%;
	position: relative;
}
